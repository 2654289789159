<template>
    <div class="text-center">
        <v-dialog v-model="showDevices" max-width="650" :persistent="false" @click:outside="closeDialog()">
            <v-card>
                <v-card-title class="headline pb-0">
                    <span>Mis Dispositivos</span>
                    <v-spacer></v-spacer>
                    <v-btn
                        x-small
                        icon
                        right
                        @click="closeDialog()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>          
                </v-card-title>

                <v-card-text>
                    <v-simple-table class="app-tables">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha de Registro
                                </th>
                                <th class="text-left">
                                    Navegador
                                </th>
                                <th class="text-left">
                                    Sistema Operativo
                                </th>
                                <th class="text-center">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in devices"
                                :key="item.id"
                                >
                                <td>
                                    {{ formatDate(item.created_at) }}
                                </td>
                                <td>
                                    <span v-if="item.browser">{{ item.browser }}</span> 
                                    <span v-else class="text--disabled">Sin información</span>
                                </td>
                                <td>
                                    <span v-if="item.os">{{ item.os }}</span> 
                                    <span v-else class="text--disabled">Sin información</span>
                                </td>
                                <td class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" icon x-small color="secondary" @click="deleteDeviceFromList(item)" :loading="isRemoving(item.id)">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { deleteDevice } from "@/api/core";

export default {
    name: "pushDevices",
    props: {
        showDevices: {
            type: Boolean,
            default: false
        },
        devices: {
            type: Array,
            default: () => []
        }
    },
    
    data() {
        return {
            removing: false
        }
    },
    
    created() {

    },

    mounted() {

    },

    methods: {
        closeDialog() {
            this.$emit('devicesListClosed')
        },

        formatDate (datetime) {
            if (!datetime) return null

            const [date, time] = datetime.split(' ')
            const [year, month, day] = date.split('-')
            const [hours, minutes, seconds] = time.split(':')

            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`
        },

        isRemoving(id) {
            return id == this.removing
        },

        async deleteDeviceFromList(item) {
            try {
                let params = {
                    endpoint: item.endpoint
                }
                this.removing = item.id
                await deleteDevice(params)
                this.removing = false
                this.$emit('deviceDeleted')
            } catch(e) {
                this.removing = false
            }
        }
    }
}
</script>
