<template>
  <v-container class="app-page">
    <v-card class="pa-3 mb-3 form-inputs-disabled-solid">
      <h1 class="titulo">Perfil del Usuario</h1>
      <v-form ref="perfilForm" @submit.prevent="save" autocomplete="off">
        <v-row class="px-3" style="max-width: 500px; margin: 0 auto">
          <div class="d-flex flex-column mt-4">
            <v-row>
              <v-col class="text-center flex-grow-0 order-1 order-sm-1">
                <v-avatar size="60" class="mb-3">
                  <img :src="userAvatar" alt="Avatar del usuario" />
                  <v-file-input
                    @change="uploadAvatarPerfil"
                    name="avatar"
                    v-model="userEdit.file"
                    accept="image/jpeg"
                    placeholder="Elige una imagen"
                    prepend-icon="mdi-camera"
                    hide-input
                    label="Avatar"
                    dense
                    class="upload-avatar"
                  ></v-file-input>
                </v-avatar>
              </v-col>
              <v-col class="order-2 order-sm-2 flex-grow-1">
                <v-row class="mb-0 ml-0 text-disabled">
                  <v-col cols="12" class="pa-0">
                    <p class="mb-0 text-disabled" style="opacity: 0.8">
                      <small
                        class="text-secondary d-inline-block"
                        style="width: 55px"
                        >Número:</small
                      >
                      <span>{{ userEdit.id_persona }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <p class="text-disabled mb-0" style="opacity: 0.8">
                      <small
                        class="text-secondary d-inline-block"
                        style="width: 55px"
                      >
                        Nombre:
                      </small>
                      <span class="font-weight-bold">{{ userEdit.name }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <p class="text-disabled mb-0" style="opacity: 0.8">
                      <small
                        class="text-secondary d-inline-block"
                        style="width: 55px"
                      >
                        Usuario: </small
                      >{{ userEdit.usuario }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0 mb-4 flex-grow-1" style="position: relative">
                <VuePhoneNumberInput 
                  v-model="userEdit.completeNumber"
                  :translations="translations"
                  color="#ff7f04"
                  error-color="#ff5252"
                  valid-color="#6f6f6f"
                  @update="onUpdateCelular"
                  :default-country-code="user.country_code" 
                />

                <v-chip
                  v-if="!userEdit.mobile_verified"
                  x-small
                  color="error"
                  class="perfil-chip mr-2 heart z2"
                  :disabled="user_mobile_loading"
                  v-on:click="generarCodigoVerificacion()"
                >
                  <span v-show="!user_mobile_loading">Verificar</span>
                  <span v-show="user_mobile_loading">Enviando...</span>
                </v-chip>

                <v-chip
                  v-if="userEdit.mobile_verified"
                  x-small
                  outlined
                  color="success"
                  class="perfil-chip mr-2 z2"
                >
                  Verificado
                </v-chip>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                class="pt-2 mb-1 flex-grow-1"
                style="max-height: 50px; position: relative"
              >
                <v-text-field
                  label="Correo electrónico"
                  type="email"
                  v-model="userEdit.email"
                  v-on:change="changeEmail()"
                  :rules="[rules.email]"
                  dense
                  class="mb-0"
                ></v-text-field>
                <v-chip 
                  v-if="userEdit.email_verified"
                  x-small
                  outlined
                  color="success"
                  class="perfil-chip mr-2"
                >
                  Verificado
                </v-chip>

                <v-chip
                  v-if="!userEdit.email_verified && show_verified_link"
                  x-small
                  color="error"
                  class="perfil-chip mr-2 heart"
                  :disabled="user_email_loading"
                  v-on:click="verificarEmail()"
                >
                  <span v-show="!user_email_loading">Verificar</span>
                  <span v-show="user_email_loading">Enviando...</span>
                </v-chip>

                <v-chip
                  v-if="!userEdit.email_verified && !show_verified_link"
                  x-small
                  outlined
                  color="error"
                  class="perfil-chip mr-2"
                  :disabled="user_email_loading"
                  :loading="user_email_loading"
                  v-on:click="refreshEmail()"
                >
                  Actualizar
                </v-chip>
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="userEdit.direccion"
                  label="Dirección"
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="pt-0">
                <v-btn plain block @click="password.dialog = true">
                  Cambiar Contraseña
                  <v-icon right dark> mdi-form-textbox-password </v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12 mt-4" class="pt-0 text-center">
                <v-btn
                  type="submit"
                  color="primary"
                  class="btn"
                  small
                  :disabled="saving"
                  :loading="saving"
                  >Guardar</v-btn
                >
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>

            <v-row>
              <v-col cols="12" sm="12" class="text-center py-1">
                <v-btn
                  plain
                  v-if="devices.length == 0"
                  @click="pushInstall()"
                  :disabled="devices.length > 0"
                >
                  Activar Push
                  <v-icon right dark> mdi mdi-bell-ring </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="text-center py-1">
                <v-btn plain @click="sendTestPush()" :loading="sendingPush">
                  Enviar una alerta de prueba
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12" class="text-center py-1">
                <v-btn plain @click="openDevices()" :loading="loadingDevices">
                  Mis dispositivos
                  <v-icon right dark> mdi mdi-cellphone-link </v-icon>
                </v-btn>
                <!--<v-progress-linear v-model="loadingDevices" :indeterminate="true"></v-progress-linear>-->
              </v-col>
              <v-col
                cols="12"
                sm="12"
                class="text-center"
                v-if="user.estado == 0"
              >
                <v-btn
                  depressed
                  @click="logout()"
                  color="primary"
                  v-if="user.estado == 0"
                >
                  Salir
                </v-btn>
              </v-col>
            </v-row>

            <!-- <v-select
              v-model="userEdit.id_estado_civil"
              :items="options.estado_civil"
              label="Estado Civil"
              placeholder="selecciona una opción"
              :disabled="!isEditing"
              dense
              item-text="value"
              item-value="id"
            ></v-select>

              <v-switch
                v-model="pushNotification"
                label="Notificaciones Push"
              ></v-switch>-->
          </div>
        </v-row>
      </v-form>
    </v-card>

    <v-dialog v-model="dialogUpdateData" max-width="450" :persistent="true">
      <v-card>
        <v-card-title class="headline"> Atención </v-card-title>

        <v-card-text>
          Debes cambiar tu contraseña por una más segura y actualizar tu
          información!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            text
            @click="aceptarCambiarPassword()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <push-devices
      :showDevices="showDialogDevices"
      :devices="devices"
      @deviceDeleted="reloadDevicesList()"
      @devicesListClosed="showDialogDevices = false"
    ></push-devices>

    <v-dialog data-app v-model="showMessage" width="300">
      <v-card color="#FFF">
        <v-card-title>Atención</v-card-title>
        <v-card-text class="pb-6">
          No tienes ningun dispositivo configurado para recibir alertas
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="password.dialog"
      max-width="450"
      :persistent="false"
      @click:outside="closePasswordDialog()"
    >
      <v-card>
        <v-card-title class="headline">
          <span>Cambiar contraseña</span>
          <v-spacer></v-spacer>
          <v-btn x-small icon right @click="closePasswordDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2">
          <v-row justify="space-between">
            <v-col cols="12" md="12" class="pt-0">
              <v-form ref="perfilPasswordForm" autocomplete="off">
                <v-text-field
                  label="Contraseña"
                  :rules="[rules.required]"
                  v-model="password.new_password"
                  placeholder=" "
                  dense
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>

                <v-text-field
                  class="pt-3"
                  label="Repetir contraseña"
                  v-model="password.confirm_password"
                  dense
                  :rules="[rules.confirmation]"
                  placeholder=" "
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>

                <v-progress-linear
                  v-if="password.new_password != ''"
                  :value="progress"
                  :color="color"
                  height="7"
                  class="mt-2"
                ></v-progress-linear>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            type="button"
            @click="changePassword()"
            color="primary"
            class="btn mt-0 mb-6"
            small
            :disabled="password.changing"
            :loading="password.changing"
            >Cambiar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogControlCodigo" max-width="450">
      <v-card>
        <v-card-title class="font-weight-bold">
          Ingresar Código de Verificación
        </v-card-title>
        <v-card-subtitle class="font-weight-bold"
          >Enviamos un código de verificación a tu teléfono +{{ mask_prefix }}
          {{ mask_mobile }}.</v-card-subtitle
        >

        <v-card-text>
          <v-form ref="codigoPasswordForm" autocomplete="off">
            <v-otp-input
              v-model="codigo"
              :disabled="sendingCode"
              @finish="sendCode"
              length="6"
            ></v-otp-input>
            <v-overlay absolute :value="sendingCode">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
            <v-row>
              <v-col cols="12" sm="12" class="text-left">
                Si no recibiste el código, podes reenviarlo.
                <v-btn
                  text
                  :disabled="screen_time != null"
                  :loading="user_reenviar_waap_loading"
                  v-on:click="reenviarCodigoViaWaap()"
                >
                  Reenviar código
                  <template v-slot:loader>
                    <span>Reenviando...</span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="text-left">
                Si luego de reenviar el codigo, no lo recibes, podes probar
                enviando un
                <v-btn
                  text
                  :disabled="screen_time != null"
                  :loading="user_reenviar_sms_loading"
                  v-on:click="reenviarCodigoViaSms()"
                >
                  SMS
                  <template v-slot:loader>
                    <span>Reenviando...</span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="screen_time">
              <v-col cols="12" sm="12" class="text-center">
                <code class="text-primary">
                  <v-icon class="mr-5 pb-1">fas fa-clock</v-icon>
                  {{ screen_time }} min
                </code>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey darken-1" text @click="closeDialogCode()">
            Cancelar
          </v-btn>
          <v-btn
            :disabled="sendingCode"
            :loading="sendingCode"
            color="grey darken-1"
            text
            @click="sendCode()"
          >
            Verificar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  updatePerfil,
  uploadAvatar,
  getDevicesList,
  sendMePush,
  updatePassword,
  generarCodigoVerificacion,
  requestPerfilSendCode,
  requestVerificarEmail,
  requestRefreshEmail,
  resetVerification,
} from "@/api/core";
import { requestResendCode } from "@/api/auth";
import pushMixin from "@/mixins/push";
import PushDevices from "@/components/push/Devices";
import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.component("vue-phone-number-input", VuePhoneNumberInput);

const TIME_LOCK = 120;

export default {
  name: "perfil",
  mixins: [pushMixin],
  components: {
    PushDevices,
    VuePhoneNumberInput,
  },
  data() {
    return {
      prefixes: [
        {
          value: "598",
          name: "+598",
        },
        {
          value: "54",
          name: "+54",
        },
        {
          value: "55",
          name: "+55",
        },
      ],
      password: {
        dialog: false,
        changing: false,
        new_password: "",
        confirm_password: "",
      },
      sendingPush: false,
      loadingDevices: false,
      showMessage: false,
      devices: [],
      showDialogDevices: false,
      show1: false,
      show2: false,
      model: null,
      saving: false,
      dialogUpdateData: false,
      options: {},
      userEdit: {
        id_persona: null,
        file: null,
        avatar: null,
        name: null,
        usuario: null,
        email: null,
        direccion: null,
        telefono_celular: null,
        telefono_prefix: null,
        mobile_verified: false,
        email_verified: false,
        completeNumber: null,
        country_code: null
      },
      verified: {
        telefono_celular: null,
        telefono_prefix: null,
        email: null,
      },
      translations: {
        countrySelectorLabel: "Prefijo",
        countrySelectorError: "Codigo de pais",
        phoneNumberLabel: "Nro. de celular",
        example: "Ejemplo :",
      },
      // *******************************
      // Verificacion mobile y email
      token: null,
      codigo: null,
      user_mobile_loading: false,
      user_email_loading: false,
      user_reenviar_waap_loading: false,
      user_reenviar_sms_loading: false,
      dialogControlCodigo: false,
      sendingCode: false,
      mask_mobile: "",
      mask_prefix: "",
      show_verified_link: true,

      tiempoRestante: null,
      intervaloTiempo: null,
      screen_time: null,
      user_verify_loading: false,
      // *******************************

      rules: {
        required: (value) => !!value || "Requerido.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return value == "" || pattern.test(value) || "E-mail incorrecto.";
        },
        confirmation: () => {
          return (
            this.password.new_password == this.password.confirm_password ||
            "Las contraseñas no coinciden"
          );
        },
      },
    };
  },

  created() {
    this.$store.commit("setSnackBar", { show: false, message: null });
    this.dialogUpdateData = this.user.estado == 0;
    // console.log(this.user)
  },

  computed: {
    ...mapGetters(["user"]),

    userAvatar() {
      if (this.userEdit.avatar) return this.userEdit.avatar;
      return process.env.BASE_URL + "img/user.jpeg";
    },

    progress() {
      if (this.password.new_password == null) return 0;
      let strength = this.calculateStrength();
      return Math.min(100, (strength * 100) / 5);
    },

    color() {
      return ["error", "warning", "success"][Math.floor(this.progress / 40)];
    },

    //   pushNotification: {
    //       get() {
    //         return this.settingPush
    //       },

    //       set(newName) {
    //         if(newName) {
    //           this.pushInstall()
    //         } else {
    //           this.$store.dispatch("user/update_push_notification", {
    //               val: newName
    //           })
    //           // Reseteamos el flag para volver a mostrar la modal de activacion de PUSH
    //           this.$store.dispatch("user/update_push_notification_window", {
    //               val: false
    //           })
    //         }
    //         return newName
    //       }
    //   }
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Perfil del Usuario");
    this.$store.commit("stopProcessing");
    this.$store.commit("setSnackBar", { show: false, message: null });
    this.fillPerfil();
    this.reloadDevicesList();
    // console.log("EN EL MOUNTED perfil")
    // console.log(this.user)
  },

  methods: {
    onUpdateCelular(payload) {
      const prefix = payload.countryCallingCode;
      const phone = payload.nationalNumber;
      const country_code = payload.countryCode;
      this.userEdit.telefono_celular = phone;
      this.userEdit.telefono_prefix = prefix;
      this.userEdit.country_code = country_code;
      this.changeMobile();
    },

    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      });
    },
    normalizeNumber(number) {
      // Elimina todos los espacios en blanco
      let normalized = number.replace(/\s+/g, '');
      // Elimina todos los ceros iniciales
      normalized = normalized.replace(/^0+/, '');
      return normalized;
    },
    equalsMobiles(mobile1, mobile2) {
      const normalizedNumber1 = this.normalizeNumber(mobile1);
      const normalizedNumber2 = this.normalizeNumber(mobile2);
      return normalizedNumber1 === normalizedNumber2;
    },
    async changeMobile() {
      if (!this.equalsMobiles(this.verified.telefono_celular, this.userEdit.telefono_celular)) {
        this.userEdit.mobile_verified = false;
        /*let data = await resetVerification({ field: "mobile" });

        if (Object.prototype.hasOwnProperty.call(data, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: data.token,
          });
        }*/
      } else {
        this.userEdit.mobile_verified = this.user.mobile_verified;
        /*let data = await resetVerification({ field: "mobile", turn: "1" });

        if (Object.prototype.hasOwnProperty.call(data, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: data.token,
          });
        }*/
      }
    },
    async changeEmail() {
      if (this.verified.email != this.userEdit.email) {
        this.userEdit.email_verified = false;
        // Setear en el server
        let data = await resetVerification({ field: "email" });

        if (Object.prototype.hasOwnProperty.call(data, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: data.token,
          });
        }
      } else {
        this.userEdit.email_verified = this.user.email_verified;
      }
    },
    async verificarEmail() {
      if (!this.userEdit.email) {
        this.showError("Debes ingresar el email.");
        return false;
      }

      try {
        this.user_email_loading = true;
        let params = {
          email: this.userEdit.email,
        };
        await requestVerificarEmail(params);
        this.show_verified_link = false;
        let msg =
          "Hemos enviado un email de verificacion. Por favor revisa tu casilla de correo y verifica tu cuenta..";
        this.$store.commit("setSnackBar", {
          message: msg,
          show: true,
          color: "#008f39",
          timeout: 10000,
        });
      } catch (e) {
        console.log(e);
        // this.dialogMessage = e.response.data.error;
        // this.dialogControl = true;
      }
      this.user_email_loading = false;
    },

    async refreshEmail() {
      try {
        this.user_email_loading = true;
        let params = {
          email: this.userEdit.email,
        };

        let data = await requestRefreshEmail(params);
        this.user_email_loading = false;
        this.show_verified_link = true;

        if (data.email_verified) {
          this.userEdit.email_verified = true;
          if (Object.prototype.hasOwnProperty.call(data, "token")) {
            this.$store.dispatch("user/refresh_token", {
              token: data.token,
            });
          }
          let msg =
            "Tu dirección de correo electrónico ha sido verificada correctamente.";
          this.$store.commit("setSnackBar", {
            message: msg,
            show: true,
            color: "#008f39",
            timeout: 10000,
          });

          this.verified.email = this.user.email;
        } else {
          let msg =
            "Tu dirección de correo electrónico aún no ha sido verificada.";
          this.showError(msg);
        }
      } catch {
        this.user_email_loading = false;
      }
    },

    validarNumeroCelularUruguayo(numero) {
      // Eliminar espacios en blanco al inicio y al final
      numero = numero.trim().replace(/\s+/g, '');

      // Verificar el largo
      if (numero.length === 8 && numero.startsWith("9")) {
        // Número de 8 caracteres que comienza con 9
        return true;
      } else if (numero.length === 9 && numero.startsWith("0")) {
        // Número de 9 caracteres que comienza con 0
        return true;
      } else {
        // No cumple con las condiciones
        return false;
      }
    },

    async generarCodigoVerificacion() {
      let params = {
        telefono_prefix: this.userEdit.telefono_prefix,
        telefono_celular: this.userEdit.telefono_celular,
      };

      if (!this.userEdit.telefono_prefix) {
        this.showError("Debes ingresar el prefijo.");
        return false;
      }

      if (!this.userEdit.telefono_celular) {
        this.showError("Debes ingresar tu número de celular.");
        return false;
      }

      if (
        this.userEdit.telefono_prefix == "598" &&
        !this.validarNumeroCelularUruguayo(this.userEdit.telefono_celular)
      ) {
        this.showError("El número de celular ingresado es incorrecto.");
        return false;
      }

      try {
        this.user_mobile_loading = true;
        let data = await generarCodigoVerificacion(params);

        if (data.code != 200) {
          this.showError(data.error);
          this.user_mobile_loading = false;
          return;
        }

        this.token = data.token;
        let msg =
          "Hemos enviado un código de verificacion al teléfono " +
          data.mask_mobile +
          " para verificar tu número.";
        this.$store.commit("setSnackBar", {
          message: msg,
          show: true,
          color: "#008f39",
          timeout: 10000,
        });
        this.initClock();
        this.dialogControlCodigo = true;
      } catch (e) {
        // this.dialogMessage = e.response.data.error;
        // this.dialogControl = true;
      }
      this.user_mobile_loading = false;
    },

    async sendCode() {
      this.sendingCode = true;

      let params = {
        code: this.codigo,
        token: this.token,
      };

      try {
        let data = await requestPerfilSendCode(params);
        let msg =
          "El código de verificacion ha sido validado correctamente. Tu número de celular se encuentra verificado.";
        this.$store.commit("setSnackBar", {
          message: msg,
          show: true,
          color: "#008f39",
          timeout: 10000,
        });
        this.userEdit.mobile_verified = true;
        if (Object.prototype.hasOwnProperty.call(data, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: data.token,
          });
        }
        this.codigo = "";
        this.dialogControlCodigo = false;
        this.sendingCode = false;

        this.verified.telefono_celular = this.user.telefono_celular;
        this.verified.telefono_prefix = this.user.telefono_celular;
      } catch {
        this.sendingCode = false;
      }
    },

    closeDialogCode() {
      this.codigo = "";
      this.dialogControlCodigo = !this.dialogControlCodigo;
    },

    updateClock() {
      // Actualizar el tiempo restante
      this.tiempoRestante--;

      const minutos = Math.floor(this.tiempoRestante / 60);
      const segundos = this.tiempoRestante % 60;

      const segundosFormateados = segundos < 10 ? `0${segundos}` : segundos;

      // Mostrar el tiempo en la consola (puedes ajustar esto según tu necesidad)
      this.screen_time = `${minutos}:${segundosFormateados}`;

      // Verificar si el tiempo ha llegado a 0
      if (this.tiempoRestante < 0) {
        this.tiempoRestante = null;
        this.screen_time = null;
        // Limpiar el intervalo para detener el contador
        clearInterval(this.intervaloTiempo);
      }
    },

    initClock() {
      this.tiempoRestante = TIME_LOCK;
      this.intervaloTiempo = setInterval(this.updateClock, 1000);
    },

    async reenviarCodigoViaSms() {
      this.user_reenviar_sms_loading = true;

      let params = {
        token: this.token,
        method: "sms",
      };

      try {
        let data = await requestResendCode(params);
        let msg =
          "Hemos reenviado el código de verificacion al teléfono " +
          data.mask_mobile +
          ".";
        this.$store.commit("setSnackBar", {
          message: msg,
          show: true,
          color: "#008f39",
        });
        this.initClock();
      } catch (error) {
        this.showError(error);
      }
      this.user_reenviar_sms_loading = false;
    },

    async reenviarCodigoViaWaap() {
      this.user_reenviar_waap_loading = true;

      let params = {
        token: this.token,
        method: "wasap",
      };

      try {
        let data = await requestResendCode(params);
        if (data.code != 200) {
          this.showError(data.error);
          this.user_reenviar_waap_loading = false;
          return;
        }
        let msg =
          "Hemos reenviado el código de verificacion al teléfono " +
          data.mask_mobile +
          ".";
        this.$store.commit("setSnackBar", {
          message: msg,
          show: true,
          color: "#008f39",
        });
        this.initClock();
      } catch (error) {
        this.showError(error);
      }
      this.user_reenviar_waap_loading = false;
    },

    closePasswordDialog() {
      this.password.dialog = false;
    },

    openPasswordDialog() {
      this.password.dialog = true;
    },

    async sendTestPush() {
      this.pushInstall();
      this.showDialogDevices = false;
      if (this.devices.length == 0) {
        this.dialogMessage();
      } else {
        this.sendingPush = true;
        await sendMePush();
        this.sendingPush = false;

        this.$store.commit("setSnackBar", {
          message: "Se ha enviado la alerta correctamente!",
          show: true,
          color: "#008f39",
          timeout: 5000,
        });
      }
    },

    async openDevices() {
      try {
        this.loadingDevices = true;
        this.showDialogDevices = false;
        const data = await getDevicesList();
        this.devices = data.records;
        this.loadingDevices = false;
        if (this.devices.length == 0) {
          this.dialogMessage();
        } else {
          this.showDialogDevices = true;
        }
      } catch (e) {
        this.loadingDevices = false;
      }
    },

    async reloadDevicesList() {
      try {
        this.loadingDevices = true;
        const data = await getDevicesList();
        this.devices = data.records;
        this.loadingDevices = false;
      } catch (e) {
        this.loadingDevices = false;
      }
    },

    dialogMessage() {
      this.showMessage = true;
    },

    calculateStrength() {
      let strength = 0;
      let password = this.password.new_password;
      if (password.match(/[a-z]+/)) {
        strength += 1;
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1;
      }
      if (password.match(/[0-9]+/)) {
        strength += 1;
      }
      if (password.match(/[\W]+/)) {
        strength += 1;
      }
      if (password.length > 6) {
        strength += 1;
      }
      return strength;
    },

    async changePassword() {
      if (this.$refs.perfilPasswordForm.validate(true)) {
        this.password.changing = true;

        try {
          let data = await updatePassword({
            new_password: this.password.new_password,
          });

          if (Object.prototype.hasOwnProperty.call(data, "token")) {
            this.$store.dispatch("user/refresh_token", {
              token: data.token,
            });
          }

          this.$store.commit("setSnackBar", {
            message: "La contraseña ha sido cambiada correctamente!",
            show: true,
            color: "#008f39",
            timeout: 5000,
          });
        } catch (e) {
          this.password.changing = false;
        }

        this.password.changing = false;
        this.closePasswordDialog();
      }
    },

    aceptarCambiarPassword() {
      this.dialogUpdateData = !this.dialogUpdateData;
      this.openPasswordDialog();
    },

    async canSave() {
      if (!this.userEdit.email_verified) {
        let params = {
          email: this.userEdit.email,
        };

        let data = await requestRefreshEmail(params);

        if (data.email_verified) {
          this.userEdit.email_verified = true;
          if (Object.prototype.hasOwnProperty.call(data, "token")) {
            this.$store.dispatch("user/refresh_token", {
              token: data.token,
            });
          }
          this.verified.email = this.user.email;
          this.show_verified_link = false;
        }
      }

      if (this.userEdit.mobile_verified || this.userEdit.email_verified) {
        return true;
      }
      return false;
    },

    async save() {
      let canSave = await this.canSave();
      if (!canSave) {
        let msg =
          "Debes verificar tu celular o email para poder actualizar tus datos.";
        this.showError(msg);
        return false;
      }
      if (this.$refs.perfilForm.validate(true)) {
        this.saving = true;
        this.$store.commit("startProcessing", {
          value: true,
          message: "Actualizando...",
        });

        let data = await updatePerfil(this.userEdit);
        this.$store.commit("stopProcessing");

        this.updateUser();
        this.isEditing = !this.isEditing;

        if (Object.prototype.hasOwnProperty.call(data, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: data.token,
          });
        }

        this.$store.commit("setSnackBar", {
          message: "Se ha actualizado el perfil correctamente!",
          show: true,
          color: "#008f39",
          timeout: 5000,
        });
        this.saving = false;
      }
    },

    updateUser() {
      this.user.avatar = this.userEdit.avatar;
      this.user.usuario = this.userEdit.usuario;
      this.user.email = this.userEdit.email;
      this.user.direccion = this.userEdit.direccion;
      this.user.telefono_celular = this.userEdit.telefono_celular;
      //this.user.id_estado_civil = this.userEdit.id_estado_civil;
      if (this.userEdit.new_password != "") {
        this.user.estado = 1;
      }
    },

    fillPerfil() {
      this.userEdit = {
        id_persona: this.user.id_persona,
        file: null,
        avatar: this.user.avatar,
        name: this.user.name,
        usuario: this.user.usuario,
        new_password: "",
        confirm_password: "",
        email: this.user.email,
        direccion: this.user.direccion,
        telefono_celular: this.user.telefono_celular,
        telefono_prefix: this.user.telefono_celular_prefix
          ? this.user.telefono_celular_prefix
          : "598",
        country_code: this.user.country_code,
        mobile_verified: this.user.mobile_verified,
        email_verified: this.user.email_verified,
        completeNumber: null,
      };
      if (this.userEdit.telefono_celular && this.userEdit.telefono_prefix) {
        this.userEdit.completeNumber = this.userEdit.telefono_celular;
      }
      if (this.user.mobile_verified) {
        this.verified.telefono_celular = this.user.telefono_celular;
        this.verified.telefono_prefix = this.user.telefono_prefix;
      }
      if (this.user.email_verified) {
        this.verified.email = this.user.email;
      }
    },

    async uploadAvatarPerfil() {
      if (this.userEdit.file) {
        this.$store.commit("startProcessing", {
          value: true,
          message: "Subiendo imagen...",
        });
        let formData = new FormData();

        formData.append("file", this.userEdit.file);

        var result = await uploadAvatar(formData);

        this.userEdit.avatar = result.url;
        this.user.avatar = result.url;
        this.userEdit.file = null;
        if (Object.prototype.hasOwnProperty.call(result, "token")) {
          this.$store.dispatch("user/refresh_token", {
            token: result.token,
          });
        }
        this.$store.commit("stopProcessing");
      } else {
        console.log("there are no files.");
      }
    },

    showError(error) {
      let message = null;
      if (typeof error === "string") {
        message = error;
      } else {
        let error_data = error.response.data;

        if (
          Object.prototype.hasOwnProperty.call(error_data, "code") &&
          Object.prototype.hasOwnProperty.call(error_data, "message")
        ) {
          message =
            error_data.message +
            (error_data.code != 1000 ? " | Código: " + error_data.code : "");
        } else if (error_data.errors) {
          let errors_messages = error_data.errors;
          message = "<ul>";
          for (let m in errors_messages) {
            message += "<li>" + errors_messages[m].join(" - ") + "</li>";
          }
          message += "</ul>";
        } else if (error_data.error) {
          message = error_data.error;
        } else {
          message =
            "Ha ocurrido un error en el servidor. Intenta conectarte nuevamente.";
        }
      }

      // Mostrar un mensaje
      this.$store.commit("setSnackBar", {
        message: message,
        show: true,
        color: "#cc0605",
      });
    },
  },
};
</script>

<style>
.z2 {
  z-index: 2;
}

.perfil-chip {
  position: absolute !important;
  right: 0px;
  top: 0px;
}

.titulo {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem !important;
  line-height: 1.5rem !important;
  color: #e5e5e5;
  margin-bottom: 20px !important;
  text-align: center;
}

.upload-avatar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.upload-avatar .v-icon--link {
  opacity: 0;
}
.upload-avatar:hover .v-icon--link {
  opacity: 1;
}
.upload-avatar .v-input__prepend-outer,
.upload-avatar .v-input__icon--prepend {
  width: 100%;
  height: 100%;
}
.upload-avatar .v-input__prepend-outer {
  margin-right: 0 !important;
}

#MazPhoneNumberInput {
  display: grid;
  grid-template-columns: 75px auto;
}

#MazPhoneNumberInput .country-selector__toggle {
  right: -5px;
  transform: scale(0.6);
}

#MazPhoneNumberInput input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0 !important;
  border-color: #949494;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  box-shadow: none !important;
}

#MazPhoneNumberInput .country-selector__label,
#MazPhoneNumberInput .country-selector__country-flag {
  left: 0;
}

#MazPhoneNumberInput .country-selector {
  width: 75px;
}

#MazPhoneNumberInput .country-selector__input {
  padding-left: 27px;
  transition: all 0.1s ease;
  width: 90px;
  font-size: 12px;
}

#MazPhoneNumberInput .select-country-container {
  width: 30px;
}

#MazPhoneNumberInput .input-tel__label,
#MazPhoneNumberInput .country-selector__label {
  top: -2px;
  font-size: 12px;
  transition: all 0s ease;
}
</style>
